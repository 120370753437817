import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";

import { ADMIN_NAV_MENU } from "@root/core/constants/nav-menu";
import { Icon } from '@ant-design/compatible';
import { Link } from "react-router-dom";
import { NavbarMenu } from "@core/interfaces";
import { useLocation } from "react-router";
import useTranslate from "@core/hooks/useTranslate";

const { Sider, Content } = Layout;

interface Props {
  authenticated: boolean;
  content: JSX.Element;
}


const LayoutCpn = ({ authenticated, content }: Props) => {
  const [t] = useTranslate();
  const location = useLocation();
  const [path, setPath] = useState<string>("");
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    if (location.pathname !== "/admin/login") {
      const pathUrl: any = ADMIN_NAV_MENU.find((item: NavbarMenu) => {
        if (location.pathname.indexOf(item.url) === 0) return item.url;
      });
      setPath(pathUrl?.url);
    }
  }, [location.pathname]);

  const toggleCollapsed = () => setCollapsed(prev => !prev)
  // const reloadPage = ()=> window.location.reload();
  const renderAdminOrStoreHeader = () => {
    return authenticated ? (
      <>
        <Layout className="navigation">
          <Sider collapsedWidth="48" width="230" className="side-bar__menu" collapsible collapsed={collapsed} onCollapse={toggleCollapsed}>
            <Menu
              mode="inline"
              style={{ height: "100%", borderRight: 0 }}
              selectedKeys={[path]}
            >
              {ADMIN_NAV_MENU.map((item: any, index: number) => (
                <Menu.Item key={item.url} icon={<Icon type={item.icon} />} >
                  <Link to={item.url}>{t(item.name)}</Link>
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
          <Layout>
            <Content
              style={{
                minHeight: 280,
              }}
            >
              {content}
            </Content>
          </Layout>
        </Layout>
      </>
    ) : null;
  };

  return renderAdminOrStoreHeader();
};

export default React.memo(LayoutCpn);
